 
  .clickable {
    cursor: pointer;
    .input-group-text {
      color: #fff;
    background-color: #556ee6;
    border-color: #556ee6;

    }
  }
 .error{
   font-size: 12px;
   font-weight: 600;
   color: red;
 }